import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { FormName, initialize as initializeForm, reset, change } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import moment from 'moment';
import { getMunicipios, UnidadMedida } from '../../../utility/constants';
import { constant, forInRight } from 'lodash';

let url = 'ordenventa' ;
let form = 'PeticionVentaForm';
let dirGrid = '/peticionesventa';

export const constants ={
    LOADER: 'PV_LOADER',
    DATA: 'PV_DATA',
    DATAREFUSE: `PV_DATA_DATAREFUSE`,
    ITEM: `PV_ITEM`,
    PAGE: `PV_PAGE`,
    ORDERING: `PV_ORDERING`,
    SEARCH: `PV_SEARCH`,
    SET_EMPRESAS:'EMPRESAS_PV',
    SET_CHILDS:'SET_CHILDS',
    SET_PENDIENTES:'SET_PENDIENTES',
    CLIENTE_PV: 'CLIENTE_PV',
    SET_ES_CF: 'SET_ES_CF',
    SET_REGIONES_PV: 'SET_REGIONES_PV',
    SET_MUNICIPIOS_PV: 'SET_MUNICIPIOS_PV',
    SET_MUNICIPIOS_BANCOS: 'SET_MUNICIPIOS_BANCOS_PV',
    SET_DEPARTAMENTO: 'SET_DEPARTAMENTO',
    SET_MUNICIPIO: 'SET_MUNICIPIO',
    SET_MODAL: 'SET_MODAL_PV',
    SET_TIENE_NITS: 'SET_TIENE_NITS',
    SET_ULTIMO_CLIENTE: 'SET_ULTIMO_CLIENTE'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setTieneNits = tieneNits =>({
    type:constants.SET_TIENE_NITS,
    tieneNits
})


const listar = (page = 1, estate) => (dispatch, getStore) => {
    const resource = getStore().peticionV;
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.state = '1';
    params.es_rechazado = false
    if(visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = me.id
    }
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch({type:constants.DATA, data:response});
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        // dispatch(initializeForm([]))
    });
};

const listarRefuse = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().peticionV;
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.state = '1';
    params.es_rechazado = true;
    if(visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = me.id
    }
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        // console.log(response)
        dispatch({type: constants.DATAREFUSE ,data: response});
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    params.state = '1';

    api.get(`${url}/${id}`, params).then((response) => {
        if (response.pago_inmediato === true){
            response.pago_inmediato = 'true';}
        else{response.pago_inmediato = 'false';}
        response.direccion = {
            value: response.direccion,
            label: response.direccion
        }
        if (response.fecha){
            const fechaN = response.fecha
            var time = 'T00:00:00-06:00';
            response.fecha = fechaN + time;
            // response.fecha = moment(fechaN.toString(), "YYYY-MM-DD HH:mm:ss", true);
        }
        if(response.fecha_prox_entrega){
            const fechaNa = response.fecha_prox_entrega
            var time = 'T00:00:00-06:00';
            response.fecha_prox_entrega = fechaNa + time;
        }

        _.forEach(response.proveedor.empresa, x => {
            response.Ordennits.push({
                propietario: x,
                monto_factura: response.monto,
                tipo: x.Nit
            })
        });

        // Copia de nits de referencia
        // response.Ordennits_ref = response.Ordennits;
        // response.Ordennits_ref = [];
        // _.forEach(response.Ordennits, x => {
        //     response.Ordennits_ref.unshift(x)
        // });
        response.Ordennits_ref = _.cloneDeep(response.Ordennits)


        if(response.proveedor){
            dispatch({type: constants.CLIENTE_PV, proveedor: response.proveedor.id})
        }
        _.forEach(response.Ordennits, x => {
            x.propietario.label = x.propietario.Nit;
            x.propietario.value = x.propietario.id;

        });

        if (response.Ordennits.length > 0) dispatch(setTieneNits(true))

        dispatch({type: constants.ITEM, item: response});
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/createpv`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset(form));
        if (!!dirGrid)
            dispatch(push(dirGrid));
        dispatch({type: constants.SET_ULTIMO_CLIENTE, ultimo_cliente: ""})
    }).catch((error) => {
        let mensaje = 'Error en la creación de Peticion de Materiales';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data, otro_grid) => (dispatch) => {
    dispatch(setLoader(true));
    data.fecha = moment(new Date(data.fecha)).format("YYYY-MM-D")
    data.fecha_prox_entrega = moment(new Date(data.fecha_prox_entrega)).format("YYYY-MM-D")

    // Set de data para facturacion
    data.Ordennits = [data.Ordennits[0]]
    if (data.Ordennits[0].nit != data.Ordennits[0].propietario.Nit) {
        data.Ordennits[0].nit = data.Ordennits[0].propietario.Nit
        delete data.Ordennits[0].id
    }

    api.put(`${url}/${id}/updatepv`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid){
            if(otro_grid){
                dispatch(push(otro_grid));
            }else{
                dispatch(push(dirGrid));
            }
        }
    }).catch((e) => {
        const error = e.detail ? e.detail : "Error en la edicion"
        NotificationManager.error(error, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        dispatch(listPv(""));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const searchChange2 = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar(1,1));
};

const searchChangeRefuse = search => (dispatch) => {
    dispatch(setSearch(search));
    // console.log("Search refuse")
    dispatch(listarRefuse(1, 1));
};

const onSortChangeRefuse = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarRefuse(sort.page,1));
};


const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch(setData(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const refusePV = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/refusepv`, id).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(push(dirGrid));
    }).catch((res)=>{
        NotificationManager.error(res.detail, 'ERROR', 3000);
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const acceptPV = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/acceptpv`, id).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(push(dirGrid));
        // dispatch(push(`/ordencompra/${res.id}`));
    }).catch((res)=>{
        NotificationManager.error(res.detail, 'ERROR', 3000);
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const listChilds = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/listchildsOC`, id).then((res)=>{
        dispatch(setData(constants.SET_CHILDS, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const listPv = (val) => (dispatch, getStore) =>{
    dispatch(setLoader(true));
    const me = getStore().login.me
    const visualizar_mis_datos = me.permisos.visualizar_mis_datos
    let params = {val}
    if(visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = me.id
    }
    api.get(`${url}/listpv`, params).then((res)=>{
        dispatch(setData(constants.SET_PENDIENTES, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const setProveedor = (proveedor) => (dispatch) => {
    dispatch({
        type: constants.CLIENTE_PV,
        proveedor
    })
}

const clear = () =>(dispatch) =>{
    dispatch({type: constants.ITEM, item: {}});
};

const setCF = (es_cf) => ((dispatch, getStore) =>{
    const nit = getStore().form.PeticionVentaForm.values.Ordennits[0]
    if(nit.propietario.label == "CF" | nit.propietario.label == "C/F"){
        dispatch({type: constants.SET_ES_CF, es_cf: true})
    }
    else{
        dispatch({type: constants.SET_ES_CF, es_cf: false})
    }
})

const validateNit = index => (dispatch, getStore) => {
    const form = getStore().form.PeticionVentaForm.values
    const nit = form.Ordennits[0].propietario.label;
    const params = { nit };
    api.get(`clientes/validateNit`,params).catch((error) => {
        Swal('Error', error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.', 'error');
    }).then((response) => {
        form.Ordennits[0].nombre = response.detail.nombre;

        if (response.detail.valido == "true") {
            form.Ordennits_ref[0].nombre = form.Ordennits[0].nombre;
            form.Ordennits_ref[0].propietario.nombre = form.Ordennits[0].nombre;
            form.Ordennits_ref[0].propietario.Nit = form.Ordennits[0].propietario.value;
            form.Ordennits_ref[0].propietario.label = form.Ordennits[0].propietario.value;
        }

        dispatch(initializeForm('PeticionVentaForm', form))
    }).finally( );
};

const selectRegiones = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('region/selectregiones').then((res)=>{
        dispatch({type: constants.SET_REGIONES_PV, data:res})
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
};

const setMunicipios = (val) => (dispatch) =>{
    let municipios = getMunicipios(val)
    dispatch({type: constants.SET_MUNICIPIOS_PV, data:municipios})
};

const crearCliente = (data) => (dispatch, getStore) => {
    EliminarMunicipio()
    dispatch(setLoader(true));
    const form = getStore().form.ClientesForm.values;
    let error = false;

    if(form.facturacion){
        form.facturacion.forEach(nits =>{
            if(!nits.nombre){
                NotificationManager.error( 'El nombre del nit es requerido', 'ERROR');
                error = true
                dispatch(setLoader(false));
            }
            else{
                if(nits.nombre == "Invalido"){
                    NotificationManager.error( 'El nit es invalido', 'ERROR');
                    error = true
                    dispatch(setLoader(false));
                }
            }
        })
    }


    // Validación de Identificaciones
    if (form.facturacion && form.facturacion_ref) {
        form.facturacion.forEach((identificacion, index) => {
            if (identificacion.nombre.split(' ').join('').length == 0 || identificacion.Nit.split(' ').join('').length == 0) {
                if (identificacion.tipo == "NIT") NotificationManager.error(`NIT inválido`, 'ERROR');
                else if (identificacion.tipo == "CUI") NotificationManager.error(`CUI inválido`, 'ERROR');
                dispatch(setLoader(false));
                error = true;
            }
            else if (identificacion.nombre.split(' ').join('') == 'Invalido') {
                if (identificacion.tipo == "NIT") NotificationManager.error(`NIT inválido`, 'ERROR');
                else if (identificacion.tipo == "CUI") NotificationManager.error(`CUI inválido`, 'ERROR');
                dispatch(setLoader(false));
                error = true;
            } // Verificación NIT
            else if (identificacion.tipo == "NIT" && identificacion.Nit && identificacion.nombre) {
                let existe = _.find(form.facturacion_ref, x => x.nombre == identificacion.nombre && x.tipo=='NIT')
                if (existe && existe.Nit != identificacion.Nit) {
                    NotificationManager.error(`Datos de NIT Inválidos para ${existe.nombre}`, 'ERROR');
                    dispatch(setLoader(false));
                    error = true;
                }
            } // Verificación CUI
            else if (identificacion.tipo == "CUI" && identificacion.Nit && identificacion.nombre) {
                let existe = _.find(form.facturacion_ref, x => x.nombre == identificacion.nombre && x.tipo=='CUI')
                if (existe && existe.Nit != identificacion.Nit) {
                    NotificationManager.error(`Datos de CUI Inválidos para ${existe.nombre}`, 'ERROR');
                    dispatch(setLoader(false));
                    error = true;
                }
            }
        })
    }

    // Eliminar data de referencia de facturacion
    try { delete data['facturacion_ref'] }
    catch (e) { }


    if(!error){
        api.post(`clientes`, data).then((data) => {
            NotificationManager.success('Registro creado', 'Éxito', 3000);
            dispatch(reset('ClientesForm'));
            dispatch({type: constants.SET_MODAL, open_modal: false})
            dispatch({type: constants.SET_ULTIMO_CLIENTE, ultimo_cliente: data})
            dispatch({
                type: constants.CLIENTE_PV,
                proveedor: data.id
            })
            if(data.facturacion && data.facturacion.length){
                let tiene_cf = false
                data.facturacion.forEach(empresa =>{
                    if(empresa.Nit == "CF" | data.facturacion.Nit == "C/F"){
                        if(empresa.nombre=="Consumidor Final"){
                            tiene_cf=true
                        }
                    }
                })
                if(tiene_cf==false){
                    data.facturacion.push({Nit: "CF", nombre: "Consumidor Final"})
                }
            }
            else{
                data.facturacion.push({Nit: "CF", nombre: "Consumidor Final"})
            }
            let Nits = []
            data.facturacion.forEach( (item, index) => {
                Nits[index] = {
                    propietario: {id:item.id,value:item.id, label:item.Nit},
                    nombre: item.nombre,
                };
            })
            const form_pv = getStore().form.PeticionVentaForm.values
            form_pv.Ordennits = Nits
            form_pv.proveedor = data
            form_pv.proveedor.empresa = data.facturacion
            form_pv.proveedor.sucursal = data.sucursales
            dispatch(initializeForm('PeticionVentaForm', form_pv))
        }).catch((res) => {
            NotificationManager.error( res.detail ? res.detail:'Error en la creación', 'ERROR');
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
};

const validateNitForm = index => (dispatch, getStore) => {
    const form = getStore().form.ClientesForm.values
    const nit = form.facturacion[index].Nit;
    const params = { nit };
    api.get(`clientes/validateNit`,params).catch((error) => {
        Swal('Error', error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.', 'error');
    }).then((response) => {
        form.facturacion[index].nombre = response.detail.nombre;

        // Respaldo de datos de facturacion
        if (response.detail.valido == "true") {
            let nombre_completo = response.detail.first_name + " " + response.detail.last_name;
            let existe = _.find(form.facturacion_ref, x => x.nombre == nombre_completo && x.tipo=='NIT')
            if (!existe) form.facturacion_ref.push(_.cloneDeep(form.facturacion[index]));
        }

        dispatch(initializeForm('ClienteForm', form))
    }).finally( );
};

const AddMunicipio = (depart) => (dispatch, getStore) =>{
    const resource = getStore().peticionV;
    let anterior = resource.municipios_bancos
    dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:[...anterior, getMunicipios(depart)]})
}

const EliminarMunicipio = (eliminar=0) => (dispatch, getStore) =>{
    const resource = getStore().peticionV;
    const form = getStore().form.ClientesForm
    let anterior = resource.municipios_bancos
    let encontro_municipio = false
    anterior.forEach((municipio, index) =>{
        if(municipio.length == 0){
            if(encontro_municipio==true){
                anterior.pop(index)
            }
        }
        else{
            encontro_municipio=true
        }
    })
    dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:[...anterior]})
    if(eliminar==1){
        if(form.values){
            if(form.values.sucursales != undefined){
                let municipios = []
                let anterior = resource.municipios_bancos
                form.values.sucursales.forEach((sucursal, index) =>{
                    municipios[index]=(anterior[index])
                })
                dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data: municipios})
            }
        }
    }
}
const ChangeMunicipio = ( index, depto ) => (dispatch, getStore) =>{
    const resource = getStore().peticionV;
    const formulario = getStore().form.ClientesForm;

    let anterior = resource.municipios_bancos;
    anterior[index] = (getMunicipios(depto));
    dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:[...anterior]})
    formulario.values.sucursales[index].municipios = anterior[index][0].value
}
const setDepartamento = departamento => (dispatch) => {
    dispatch({type: constants.SET_DEPARTAMENTO, departamento: departamento})
}
const setMunicipio = municipio => (dispatch) => {
    dispatch({type: constants.SET_MUNICIPIO, municipio: municipio})
}
const setOpenModal = (val) => (dispatch) => {
    dispatch({type: constants.SET_MODAL, open_modal: val })
}

const setCliente = (value) => (dispatch) =>{
    dispatch({type: constants.CLIENTE_PV, proveedor: value})
}

const setDataName = (index, data=undefined) => (dispatch, getState) => {
    const form = getState().form.ClientesForm.values
    const URL_GET_CUI = "get-valid-cui";
    const cui = form.facturacion[index].Nit;
    let nameNew = "";
    //si recibe data es porque se esta editando
    if (data) {
        nameNew = data.first_name + " " + data.last_name;
        form.facturacion[index].nombre = nameNew;
        dispatch(initializeForm("ClienteForm", form));
    }
    else {
        const fetchData = async () => {
            try {
                const data = await api.post(URL_GET_CUI, {cui});
                nameNew = data.first_name + " " + data.last_name;
                form.facturacion[index].nombre = nameNew;

                // Respaldo de datos de facturacion
                if (data.first_name != "Invalido") {
                    let existe = _.find(form.facturacion_ref, x => x.nombre == nameNew && x.tipo=='CUI')
                    if (!existe) form.facturacion_ref.push(_.cloneDeep(form.facturacion[index]));
                }

                await dispatch(initializeForm("ClienteForm", form));

            } catch (error) {
                nameNew = "invalido";
                form.facturacion[index].nombre = nameNew;
                await dispatch(initializeForm("ClienteForm", form));
            }
        };
        fetchData();
    }
};

const setDataNamePeticionVentaForm = (index) => (dispatch, getState) => {
    const form = getState().form.PeticionVentaForm.values
    const URL_GET_CUI = "get-valid-cui";
    const cui = form.Ordennits[index].propietario.value;
    const fetchData = async () => {
        try {
            const data = await api.post(URL_GET_CUI, {cui});
            let nameNew = data.first_name + " " + data.last_name;
            form.Ordennits[index].nombre = nameNew;
            await dispatch(initializeForm("PeticionVentaForm", form));
        } catch (error) {
            let nameNew = "invalido";
            form.Ordennits[index].nombre = nameNew;
            await dispatch(initializeForm("PeticionVentaForm", form));
        }
    };
    fetchData();
};


const changeFieldClienteForm = (field, value) => (dispatch, getState) => {
    dispatch(change("ClienteForm", field, value));
}

const getFieldClienteFormFacturacion = (index, tipo) => (dispatch, getStore) => {
    const form = getStore().form.ClientesForm.values
    const tipo_doc = form.facturacion[index][tipo];
    return tipo_doc;
}

const getFieldFormArray = (nameForm, nameFormArray, index, tipo) => (dispatch, getStore) => {
    const form = getStore().form[nameForm].values
    const field = form[nameFormArray][index][tipo];
    return field;
}

const setFieldFormArray = (nameForm, nameFormArray, index, tipo, value) => (dispatch, getStore) => {
    const form = getStore().form[nameForm].values
    form[nameFormArray][index][tipo] = value;
    dispatch(initializeForm(nameForm, form));
}

const resetFormularioPV = () => (dispatch) => {
    dispatch(initializeForm(form, {}))
}


export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    searchChange2,
    onSortChange,
    selectEmpresas,
    refusePV,
    acceptPV,
    listChilds,
    listPv,
    setProveedor,
    clear,
    listarRefuse,
    searchChangeRefuse,
    onSortChangeRefuse,
    setCF,
    setTieneNits,
    validateNit,
    selectRegiones,
    setMunicipios,
    crearCliente,
    validateNitForm,
    AddMunicipio,
    ChangeMunicipio,
    setDepartamento,
    setMunicipio,
    setOpenModal,
    EliminarMunicipio,
    setCliente,
    changeFieldClienteForm,
    setDataName,
    getFieldClienteFormFacturacion,
    getFieldFormArray,
    setFieldFormArray,
    setDataNamePeticionVentaForm,
    resetFormularioPV,
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_CHILDS]:(state,{ data })=>{
        return{
            ...state,
            hijos: data,
        }
    },
    [constants.SET_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            pendientes: data,
        }
    },
    [constants.CLIENTE_PV]: (state, { proveedor }) => {
        return {
            ...state,
            proveedor,
        };
    },
    [constants.DATAREFUSE]:(state,{data}) => {
        return{
            ...state,
            datarefuse:data
        }
    },
    [constants.SET_ES_CF]:(state,{es_cf}) => {
        return{
            ...state,
            es_cf
        }
    },
    [constants.SET_REGIONES_PV]:(state,{ data })=>{
        return{
            ...state,
            regiones: data,
        }
    },
    [constants.SET_MUNICIPIOS_PV]:(state,{ data })=>{
        return{
            ...state,
            municipios: data,
        }
    },
    [constants.SET_MUNICIPIOS_BANCOS]:(state,{ data })=>{
        return{
            ...state,
            municipios_bancos: data,
        }
    },
    [constants.SET_DEPARTAMENTO]:(state,{departamento}) => {
        return{
            ...state,
            departamento
        }
    },
    [constants.SET_MUNICIPIO]:(state,{municipio}) => {
        return{
            ...state,
            municipio
        }
    },
    [constants.SET_MODAL]:(state,{open_modal}) => {
        return{
            ...state,
            open_modal
        }
    },
    [constants.SET_ULTIMO_CLIENTE]:(state,{ultimo_cliente}) => {
        return{
            ...state,
            ultimo_cliente
        }
    },
    [constants.SET_TIENE_NITS]:(state,{tieneNits}) => {
        return{
            ...state,
            tieneNits
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    empresas: [],
    hijos:[],
    proveedor: null,
    pendientes:[],
    tieneNits: false,
    datarefuse: {
        results: [],
        count: 0,
    },
    es_cf: false,
    regiones:[],
    municipios:[],
    municipios_bancos:[],
    departamento: "",
    municipio:"",
    open_modal: false,
    ultimo_cliente: ""
};

export default handleActions(reducers, initialState);
