import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';


let url = 'reportes' ;


export const constants ={
    LOADER: 'LOADER_COMISIONES',
    SET_INPUT_SEARCH_COMISIONES: 'SET_INPUT_SEARCH_COMISIONES',
    DATA: 'DATA_COMISIONES',
    SET_CLIENTE: 'SET_CLIENTE_COMISIONES',
    SET_POSS: 'SET_POSS',
    SET_FECHA_I: 'SET_FECHA_I_COMISIONES',
    SET_FECHA_F: 'SET_FECHA_F_COMISIONES',
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
});

const setData = (type, data) =>({
    type,
    data
})

const setCliente = (type, cliente) => ({
    type,
    cliente
});

const setPoss = (type, poss) => ({
    type,
    poss
});

const setFechaI = fecha_inicio =>({
    type:constants.SET_FECHA_I,
    fecha_inicio
});

const setFechaF = fecha_fin =>({
    type:constants.SET_FECHA_F,
    fecha_fin
});

const setSearch = (type, inputSearch) => ({
    type,
    inputSearch
});


const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteComisionesTarjetas;
    const search = resource.inputSearch;
    const params = { page, search };

    if (resource.buscador) params.search = resource.buscador;
    if (resource.cliente) params.cliente_id = resource.cliente.id;
    if (resource.poss) params.poss_id = resource.poss.id;
    if (resource.fecha_inicio) params.fecha_inicio = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin) params.fecha_fin = resource.fecha_fin.format("YYYY-MM-DD");

    dispatch(setLoader(true));
    api.get(`${url}/reporte_comisiones_tarjetas`, params).then((response) => {
        // Subtotales row
        let subtotales = {
            id: -1,
            cliente: 'SUBTOTAL',
            fecha: null,
            monto: 0,
            iva: 0,
            monto_primera_transaccion: 0,
            liquido: 0,
            comision: 0,
            iva_comision: 0,
            retension: 0,
            recibido: 0
        };
        _.forEach(response.results, (comision) => {
            subtotales.monto += comision.monto;
            subtotales.iva += comision.iva;
            subtotales.monto_primera_transaccion += comision.monto_primera_transaccion;
            subtotales.liquido += comision.liquido;
            subtotales.comision += comision.comision;
            subtotales.iva_comision += comision.iva_comision;
            subtotales.retension += comision.retension;
            subtotales.recibido += comision.recibido;
        });
        response.results.push(subtotales)
        response.results.unshift(response.totales);

        dispatch(setData(constants.DATA, response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const descargaArchivoComisionesTarjetasEP = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const resource = getStore().reporteComisionesTarjetas;
    const params = {
        fecha_inicio: resource.fecha_inicio.format("YYYY-MM-D"),
        fecha_fin: resource.fecha_fin.format("YYYY-MM-D")
    };

    if (resource.cliente) {
        params["id_cliente"] = resource.cliente.id;
    }

    api.get(`${url}/descargar_reporte_comisiones_tarjetas`, params).then((response) => {
        NotificationManager.success(response.detail, 'Éxito', 5000);
        setTimeout(() => {
            dispatch(cicloVerificacion(response.id_archivo));
        }, 500);
    }).catch((error) => {
        console.log(error)
        NotificationManager.error('Error en la Descarga del Reporte', 'Error', 5000);
        dispatch(setLoader(false));
    }).finally(() => {
    });
};

const cicloVerificacion = (id_archivo) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    setTimeout(function () {
        dispatch(descarga_estadoEP(id_archivo));
    }, 5000)
}

const descarga_estadoEP = (id_archivo) => (dispatch, getStore) => {
    let FALLO = 0;
    let FINALIZADO = 1;
    let EN_PROCESO = 2;

    let params = { id_archivo }
    api.get(`${url}/descargar_estado`, params).then((response) => {
        if (response.estado == EN_PROCESO){
            dispatch(cicloVerificacion(id_archivo));
        }
        else if (response.estado == FINALIZADO){
            dispatch(setLoader(false));
            if (response.id) {
                window.open(response.archivo, "_blank");
            }
        }
        else if (response.estado == FALLO){
            dispatch(setLoader(false));
            NotificationManager.error(response.motivo, 'Error', 5000);
        }
    }).catch(() => {
        NotificationManager.error('Error en la Descarga del Reporte', 'Error', 5000);
        dispatch(setLoader(false));
    }).finally(() => {
    });
};

const search = (search) => (dispatch, getStore) => {
    dispatch(setSearch(constants.SET_INPUT_SEARCH_COMISIONES, search));
    dispatch(listar(1));
};

const changeCliente = (value) => (dispatch) => {
    dispatch(setCliente(constants.SET_CLIENTE, value));
    dispatch(listar(1));
};

const changePoss = (value) => (dispatch) => {
    dispatch(setPoss(constants.SET_POSS, value));
    dispatch(listar());
};

const changeFechaInicio = (value) => (dispatch) => {
    dispatch(setFechaI(value));
    dispatch(listar());
};

const changeFechaFin = (value) => (dispatch) => {
    dispatch(setFechaF(value));
    dispatch(listar());
};

export const actions = {
    listar,
    search,
    descargaArchivoComisionesTarjetasEP,
    changeCliente,
    changePoss,
    changeFechaInicio,
    changeFechaFin,
}


export const reducers = {
    [constants.LOADER]:(state, { loader }) =>{
        return{
            ...state,
            loader,
        };
    },

    [constants.SET_INPUT_SEARCH_COMISIONES]:(state, { inputSearch }) => {
        return {
            ...state,
            inputSearch
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.SET_CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente
        };
    },
    [constants.SET_POSS]: (state, { poss }) => {
        return {
            ...state,
            poss
        };
    },
    [constants.SET_FECHA_I]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio
        };
    },
    [constants.SET_FECHA_F]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin
        };
    },
}


export const initialState = {
    loader:false,
    inputSearch: '',
    data: {
        results: [],
        count: 0,
    },
    cliente: null,
    poss: null,
    loadOptionsDespachos: [],
    fecha_inicio: moment(`${moment().year()}-1-1`),
    fecha_fin: moment(),
}

export default handleActions(reducers, initialState);
